<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>赛务管理</el-breadcrumb-item>
      <el-breadcrumb-item
        :to="{
          path: '/individual_manage_homeview/individual_race_management',
        }"
        >个人赛赛务工具</el-breadcrumb-item
      >
      <el-breadcrumb-item>照片墙审核</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 筛选 -->
    <el-card style="margin-top: 15px">
      <el-row class="title_row">
        <el-col>
          <span>数据筛选</span>
        </el-col>
      </el-row>
      <el-form :model="queryForm" label-width="80px" ref="queryFormRef">
        <el-row :gutter="50">
          <el-col :span="6">
            <el-form-item label="审核状态" prop="status">
              <el-select
                placeholder="请选择"
                style="width: 100%"
                v-model="queryForm.status"
                clearable
              >
                <el-option
                  v-for="item in dict_examineState"
                  :key="item.dictCode"
                  :label="item.dictLabel"
                  :value="item.dictValue"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
                <el-form-item label="赛季" prop="season">
                  <el-select
                    placeholder="请选择赛季"
                    style="width: 100%"
                    v-model="queryForm.season"
                  >
                    <el-option
                      v-for="item in dict_season"
                      :key="item.dictValue"
                      :label="item.dictLabel"
                      :value="item.dictValue"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
        </el-row>
        <el-row :gutter="20" type="flex" justify="end">
          <el-col :span="2">
            <el-button type="primary" style="width: 100%" @click="getDataList"
              >查询</el-button
            >
          </el-col>
          <el-col :span="2">
            <el-button type="primary" style="width: 100%" @click="reset"
              >重置</el-button
            >
          </el-col>
        </el-row>
      </el-form>
    </el-card>
    <!-- 数据列表 -->
    <el-card style="margin-top: 15px">
      <el-row class="title_row">
        <el-col :span="12">
          <span class="list_title">数据列表</span>
        </el-col>
      </el-row>
      <el-table
        header-cell-class-name="tableHeaderStyle"
        :data="dataList"
        border
      >
        <el-table-column type="index"></el-table-column>
        <el-table-column
          label="编号"
          prop="code"
          width="100px"
        ></el-table-column>
        <el-table-column label="图片" prop="url">
          <template scope="scope">
            <el-image
              class="imageclass"
              :src="scope.row.url"
              :preview-src-list="scope.row.urlArr"
              alt=""
              fit="contain"
            ></el-image>
          </template>
        </el-table-column>
        <el-table-column label="标题" prop="name"></el-table-column>
        <el-table-column label="上传时间" prop="createTime"></el-table-column>
        <el-table-column label="上传作者" prop="userName"></el-table-column>
        <el-table-column label="手机号" prop="phone"></el-table-column>
        <el-table-column label="审核状态" prop="auditStatus">
          <template slot-scope="scope">
            <el-tag type="warning" v-if="scope.row.status === 0" size="mini"
              >未通过</el-tag
            >
            <el-tag
              type="success"
              v-else-if="scope.row.status === 1"
              size="mini"
              >已通过</el-tag
            >
            <span v-else>未审核</span>
          </template>
        </el-table-column>
        <el-table-column label="点赞数" prop="num"></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              @click="examineClicked(scope.row.id, 1)"
              >审核通过</el-button
            >
            <el-button
              type="text"
              size="small"
              @click="examineClicked(scope.row.id, 0)"
              >拒绝</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryForm.pageNum"
        :page-sizes="[10, 20, 40, 80]"
        :page-size="queryForm.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
  </div>
</template>

<script>
import { nationalPictureWall, nationalPictureApprove } from '@/http/api'

export default {
  data () {
    return {
      total: 0,
      dict_examineState: [
        {
          dictCode: -1,
          dictLabel: '未审核',
          dictValue: -1
        },
        {
          dictCode: 0,
          dictLabel: '未通过',
          dictValue: 0
        }, {
          dictCode: 1,
          dictLabel: '已通过',
          dictValue: 1
        }
      ],
      queryForm: {
        pageNum: 1,
        pageSize: 10,
        status: '',
        season: '2023'
      },
      dataList: []
    }
  },
  created () {
    this.getSeason()
  },
  methods: {
    getSeason () {
      this.$userInfo.getDataList(this, 't_season').then(value => {
        this.dict_season = value
        this.getDataList()
      })
    },
    getDataList () {
      nationalPictureWall(this.queryForm).then((res) => {
        res.data.list.forEach(item => {
          item.urlArr = []
          item.urlArr.push(item.url)
        })
        this.dataList = res.data.list
        this.total = res.data.total
      }).catch((err) => {
        console.log('err1111', err)
      })
    },
    // 分页变动
    handleSizeChange (newSize) {
      this.queryForm.pageSize = newSize
      this.getDataList()
    },
    // 分页变动
    handleCurrentChange (newCurPage) {
      this.queryForm.pageNum = newCurPage
      this.getDataList()
    },
    reset () {
      this.$refs.queryFormRef.resetFields()
    },
    examineClicked (rowId, state) {
      var today = new Date()
      var nowData = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate()
      var formData = {
        id: rowId,
        status: state,
        note: nowData
      }
      nationalPictureApprove(formData).then((res) => {
        this.$message.success('操作成功')
        this.getDataList()
      }).catch((err) => {
        console.log('err1111', err)
      })
    }
  }
}
</script>

<style lang="less" scoped>
.imageclass {
  width: 100px;
  height: 120px;
}
</style>
